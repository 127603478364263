<template>
    <BaseArticle :img="require('@/assets/blog/article1.jpeg')"
                 @open="$emit('open')"
                 @back="$emit('back')"
                 :subject="subject"
                 :date="date"
                 :author="author"
                 :abstract="abstract"
                 :asThumb="asThumb">
        <p>在美国注册公司类型那么多，它们之间到底有什么区别？你又该选择哪种呢？<br/>
            首先，在美国的公司架构中最主流公司类型就是C-Corporation和LLC了。<br/>
            在美国公司架构中，LLC和 C-Corporation 的区别何在？<br/>
            1.LLC （Limited Liability Company）是有限责任公司；<br/>
            2.C-Corporation是股份有限公司：<br/>
            其实听到名字就能大致理解这两种公司类型的区别了：<br/>
            主要区别就在于股权、募资能力和税收上：<br/>
            LLCC-Corporation股权与C-Corporation相比，LLC没有股票，但是有membership unit，功能与C-Corp的股票相似。<br/>
            LLC不能上市在C-Corp中，成员拥有所有权的形式是股票，股票可以转让、流通。<br/>
            C-Corporation可以上市。募资有限责任公司只能由发起人集资，不能向社会公开募集资金上市后，可以向社会公开募集资金税收单一课税-LLC不一定需要在公司层面纳税双重课税-公司层面课税&支付给所有者时的个人层面课税那么到底该如何选择呢？这里告诉你最简单的选择方案：<br/>
            如果你是一个初创企业，未来计划或可能得到投资者的资金。<br/>
            那么请你选择C-Corporation。<br/>
            如果你是小型企业拥有者，个体经营者或者计划与家人或亲密的朋友共同经营小型企业。<br/>
            那么请你选择LLC。</p>
    </BaseArticle>
</template>

<script>
  import BaseArticle from '../Block/BaseArticle'

  export default {
    name: "Article1",
    components: {BaseArticle},
    props: ['asThumb'],
    data () {
      return {
        subject: "公司注册，到底该注册LLC还是Corporation？",
        date: "5/15/2019",
        author: "Yuki Zhang",
        abstract: "在美国注册公司类型那么多，它们之间到底有什么区别？你又该选择哪种呢？" +
          "首先，在美国的公司架构中最主流公司类型就是C-Corporation和LLC了。" +
          "在美国公司架构中，LLC和 C-Corporation 的区别何在？"
      }
    }
  }
</script>

<style scoped>

</style>
